<template>
  <v-card>
    <v-card-title v-show="!!title">{{ title }}</v-card-title>
    <v-card-text
      v-show="!!message"
      class="pb-1 text-format"
    >{{ message }}
      <v-list
        class="list"
        v-if="displayFormat === 'list'"
      >
        <v-list-item
          v-for="(content, idx) in list"
          :key="idx"
          class="ml-4"
        >{{content}}
        </v-list-item>
      </v-list>
      <template v-if="secured">
        <span>{{$t('t.EnterSecurityCode', {code: securityCodeGenerated})}}</span>
        <v-text-field
          class="mt-2"
          :label="$t('t.ConfirmSecurityCode')"
          v-model="securityCodeValue"
          clearable
        />
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <template v-if="consent || secured">
        <v-btn
          :disabled="securityCodeValue !== securityCodeGenerated && secured"
          @click="doAgree"
        >{{$t('t.Yes')}}</v-btn>
        <v-btn
          color="primary"
          @click="doCancel"
        >{{$t('t.No')}}</v-btn>
      </template>
      <template v-else>
        <v-btn @click="agreed">{{$t('t.Ok')}}</v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'message', 'displayFormat', 'list', 'consent', 'secured'],
  data () {
    return {
      securityCodeValue: null
    }
  },
  inject: ['agree', 'cancel', 'agreed'],
  methods: {
    doAgree () {
      this.securityCodeValue = null
      this.agreed()
    },
    doCancel () {
      this.securityCodeValue = null
      this.cancel()
    }
  },
  computed: {
    securityCodeGenerated () {
      return this.moment().format('YYYYMMDD')
    }
  }
}
</script>
<style lang="stylus" scoped>
.text-format
  white-space pre-wrap !important

.list
  overflow-x hidden
  overflow-y auto
  max-height 400px
</style>
